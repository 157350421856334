const canvasWidth = 2000
const canvasHeight = 2800
const imageWidth = 346
const imageHeight = 244
const imagePositions = [[1014, 858], [1014, 1128], [1014, 1398], [1014, 1668]]

const backgroundUrl = "/levite/_background.png"

window.customElements.define(
    "levite-chair-render",
    class LeviteChairRender extends HTMLElement {
        connectedCallback() {
            this._canvas = document.createElement('canvas')
            this._canvas.width = canvasWidth
            this._canvas.height = canvasHeight

            this._output = document.createElement('a')
            this._output.download = "Ihr Levitenstuhl.png"
            this.appendChild(this._output)

            this.renderSelection()
        }

        constructor() {
            super()
            this._selection = []
        }

        set selection(selection) {
            // two arrays are never the same in js
            if (JSON.stringify(this._selection) === JSON.stringify(selection)) {
                return
            }
            this._selection = selection

            this.renderSelection()
        }

        renderSelection() {
            if (!this._canvas || !this._output) return;

            const ctx = this._canvas.getContext("2d")
            const background = document.getElementById('levite-chair-background')

            ctx.drawImage(background, 0, 0, canvasWidth, canvasHeight)

            this._selection.forEach((val, idx) => {
                const img = document.getElementById(val)
                ctx.drawImage(img, imagePositions[idx][0], imagePositions[idx][1], imageWidth, imageHeight)
            })

            const url = this._canvas.toDataURL("image/png")

            const image = new Image()
            image.src = url;

            this._output.href = url.replace("image/png", "image/octet-stream")
            this._output.innerHTML = image.outerHTML

            setTimeout(() => {
                this._output.scrollIntoView({ block: "end", behavior: "smooth" })
            }, 100)
        }
    }
);
