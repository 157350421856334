import "konva"
import Konva from "konva";

/* This params can all be overriden via the params parameter from Elm or via a 
`config`-Object encoded as JSON-string (as used by the `tangram` content type).
This constant is also used as documentation so please make sure there is a default set for
every possible option.
*/
const defaultParams = {
  // The location of the targets. This array has 7 elements and each is defined as
  // [targetPieceTypeId, targetX, targetY, targetRotation]
  // The big (id 0) and small (id 1) triangles each exist twice and are interchangeable
  targets: [[4, 500, 286, 45], [1, 107, 251, 135], [1, 464, 393, 225], [2, 286, 72, 225],
  [3, 72, 358, 45], [0, 72, 72, 315], [0, 500, 72, 45]],
  // Is the game in editor-mode (to create the json-config string)
  editor: false,
  // An image of the outline to be shown to the user
  targetImageUrl: "/tangram/altartable.png",
  // the scale of the target image
  targetImageScale: 1.14,
  // the image to be shown after successful completion
  // optional: set to false when no image should be shown.
  winningImageUrl: "/tangram/altartable_win.png",
  // the scale of the winning image
  winningImageScale: 3,
  // the x and y offset of the winning images top left corner
  winningImageOffset: [-1502, -4033],
  // A string that will be shown in a box when the tangram has been solved
  // optional: set to false when no box should be shown
  winningText: false
}

const tangramPieceTypes = {
  bigTriangle: {
    id: 0,
    points: [0, 200, 200, 0, 400, 200],
    offsetX: 200,
    offsetY: 100,
    defaultColor: '#007f95',
    rotationMod: 360
  },
  smallTriangle: {
    id: 1,
    points: [0, 100, 100, 0, 200, 100],
    offsetX: 100,
    offsetY: 50,
    defaultColor: '#007f95',
    rotationMod: 360
  },
  mediumTriangle: {
    id: 2,
    points: [0, 200, 200, 200, 200, 0],
    offsetX: 150,
    offsetY: 150,
    defaultColor: '#007f95',
    rotationMod: 360
  },
  square: {
    id: 3,
    points: [0, 100, 100, 0, 200, 100, 100, 200],
    offsetX: 100,
    offsetY: 100,
    defaultColor: '#007f95',
    rotationMod: 90
  },
  rhomboid: {
    id: 4,
    points: [0, 0, 200, 0, 300, 100, 100, 100],
    offsetX: 150,
    offsetY: 50,
    defaultColor: '#007f95',
    rotationMod: 180
  }
}

// This has to match the ids of the tangramPieceTypes definition!
const tangramPieceArray = [
  tangramPieceTypes.bigTriangle,
  tangramPieceTypes.smallTriangle,
  tangramPieceTypes.mediumTriangle,
  tangramPieceTypes.square,
  tangramPieceTypes.rhomboid
]

window.customElements.define(
  "tangram-game",
  class TangramGame extends HTMLElement {
    connectedCallback() {
      let config = {}
      if (this.config) {
        try {
          config = JSON.parse(this.config)
        } catch (e) {
          // This can only be a syntax error. Print it to the console, but don't fail.
          console.error(e)
        }
      }

      this.settings = { ...defaultParams, ...config, ...this.params }

      // Prepare variables
      this.targetWidth = 0
      this.targetHeight = 0
      this.stageBounds = [-1000, -1000, 1000, 1000]
      this.pieceBounds = [0, 0, 0, 0]
      this.resetPinchZoom()

      // Needed for pinchZoom
      Konva.hitOnDragEnabled = true

      // Prepare the stage
      this.stage = new Konva.Stage({
        container: this,
        draggable: true
      })

      // Initialize the layers
      this.targetImageLayer = new Konva.Layer()
      this.stage.add(this.targetImageLayer)

      this.tangramPieceLayer = new Konva.Layer()
      this.stage.add(this.tangramPieceLayer)

      this.winningImageLayer = new Konva.Layer({
        opacity: 0,
        listening: false
      })
      this.stage.add(this.winningImageLayer)

      // Initialize event handlers
      this.stage.on('dragmove', () => this.fitToBounds(this.stage, this.stageBounds))
      this.stage.on('touchmove', (evt) => this.pinchZoom(evt))
      this.stage.on('touchend', () => this.resetPinchZoom())
      this.stage.on('wheel', (evt) => this.wheelZoom(evt))

      // Setup resizing
      this.resizeStageToParentContainer()
      window.addEventListener('resize', this.resizeStageToParentContainer)

      // Initialize resetButton
      this.resetButton = document.createElement('button')
      this.resetButton.innerText = "Tangram neu starten"
      this.resetButton.setAttribute('class', 'btn btn-primary reset-btn')
      this.resetButton.onclick = this.reset
      this.hideResetButton()
      this.appendChild(this.resetButton)

      // Initialize winningTextButton
      if (this.settings.winningText) {
        this.winningTextButton = document.createElement('button')
        this.winningTextButton.innerText = this.settings.winningText
        this.winningTextButton.setAttribute('class', 'btn btn-primary winning-btn')
        this.winningTextButton.onclick = this.hideWinningTextButton
        this.hideWinningTextButton()
        this.appendChild(this.winningTextButton)
      }


      // Load targetImage and setup the game
      this.targetImageEl = new Image()
      this.targetImageEl.onload = this.setup
      this.targetImageEl.src = this.settings.targetImageUrl

      // Load winningImage asynchronously if set
      if (this.settings.winningImageUrl) {
        const winningImageEl = new Image()
        winningImageEl.onload = () => this.handleLoadedWinningImage(winningImageEl)
        winningImageEl.src = this.settings.winningImageUrl
      }
    }

    disconnectedCallback() {
      window.removeEventListener('resize', this.resizeStageToParentContainer)
    }

    setup = () => {
      this.snapped = [false, false, false, false, false, false, false]

      this.targetWidth = this.targetImageEl.naturalWidth * this.settings.targetImageScale
      this.targetHeight = this.targetImageEl.naturalHeight * this.settings.targetImageScale

      this.targetImage = new Konva.Image({
        image: this.targetImageEl,
        scaleX: this.settings.targetImageScale,
        scaleY: this.settings.targetImageScale
      });
      this.targetImageLayer.add(this.targetImage)

      if (!this.settings.editor) {
        this.addGamePieces()
      } else {
        this.setupEditor()
      }

      // center stage on items
      const startX = this.targetWidth / 2
      // tangram pieces are 400px high and have 100px padding top and 100px margin to targetImage
      const startY = (this.targetHeight + 600) / 2

      this.stage.position(this.calculateScaledStageCenterCoordinates({ x: startX, y: startY }))

      this.setDefaultZoom()
      this.zoom(this.defaultZoom)
    }

    addGamePieces = () => {
      let pieces = [
        {
          type: tangramPieceTypes.rhomboid,
          x: 350,
          y: 150,
          rotation: 90
        },
        {
          type: tangramPieceTypes.bigTriangle,
          x: 100,
          y: 200,
          rotation: 90
        },
        {
          type: tangramPieceTypes.bigTriangle,
          x: 200,
          y: 100,
          rotation: 180
        },
        {
          type: tangramPieceTypes.mediumTriangle,
          x: 350,
          y: 350
        },
        {
          type: tangramPieceTypes.smallTriangle,
          x: 250,
          y: 200,
          rotation: 270
        },
        {
          type: tangramPieceTypes.smallTriangle,
          x: 100,
          y: 350,
          rotation: 0
        },
        {
          type: tangramPieceTypes.square,
          x: 200,
          y: 300,
          rotation: 0
        }
      ]

      // tangram pieces are 400px wide => their midpoint is at 200
      const baseX = this.targetWidth / 2 - 200
      // target has 100px padding top and 100px margin to pieces
      const baseY = this.targetHeight + 200

      pieces.forEach((pieceDef) => {
        this.addSingleTangramPiece(pieceDef, baseX, baseY)
      })
    }

    addSingleTangramPiece = (pieceDef, baseX, baseY) => {
      var piece = new Konva.Line({
        points: pieceDef.type.points,
        fill: pieceDef.color || pieceDef.type.defaultColor,
        stroke: 'black',
        strokeWidth: 1,
        closed: true,
        offsetX: pieceDef.type.offsetX,
        offsetY: pieceDef.type.offsetY,
        x: baseX + pieceDef.x,
        y: baseY + pieceDef.y,
        rotation: pieceDef.rotation,
        draggable: true,
        pieceTypeId: pieceDef.type.id
      })

      if (!this.settings.editor) {
        piece.on('dragend', (evt) => this.checkForSnap(evt.target))
      } else {
        piece.on('dragend', this.updateTargets)
      }
      piece.on('dragstart', (evt) => evt.target.moveToTop())
      piece.on('dragmove', (evt) => this.fitToBounds(evt.target, this.pieceBounds))
      piece.on('click tap', (evt) => this.rotatePiece(evt.target))
      piece.on('mouseover', () => document.body.style.cursor = 'pointer')
      piece.on('mouseout', () => document.body.style.cursor = 'default')
      this.tangramPieceLayer.add(piece)
    }

    // This function resets the tangram game.
    // Calling reset() in editor-mode will break stuff. But this is not a use-case, so it's fine.
    reset = () => {
      this.winningImageLayer.opacity(0)
      this.tangramPieceLayer.destroyChildren()
      this.tangramPieceLayer.opacity(1)
      this.targetImageLayer.destroyChildren()
      this.targetImageLayer.opacity(1)
      this.hideResetButton()
      this.hideWinningTextButton()
      this.setup()
    }

    showResetButton = () => {
      this.resetButton.style.display = 'block'
    }

    hideResetButton = () => {
      this.resetButton.style.display = 'none'
    }

    showWinningTextButton = () => {
      if (this.winningTextButton) {
        // There is a transition on opacity - that's not possible on display. 
        // On the other hand with only the opacity the button is still there and clickable.
        this.winningTextButton.style.display = 'block'
        // Set the opacity a tad later, otherwise the css animation won't be triggered.
        setTimeout(() => { this.winningTextButton.style.opacity = 1 }, 1)
      }
    }

    hideWinningTextButton = () => {
      if (this.winningTextButton) {
        this.winningTextButton.style.display = 'none'
        this.winningTextButton.style.opacity = 0
      }
    }

    handleLoadedWinningImage = (winningImageEl) => {
      this.winningImage = new Konva.Image({
        image: winningImageEl,
        scaleX: this.settings.winningImageScale,
        scaleY: this.settings.winningImageScale,
        x: this.settings.winningImageOffset[0],
        y: this.settings.winningImageOffset[1]
      });
      this.winningImageLayer.add(this.winningImage)
    }

    calculateNormalizedWinningImageCenter = () => {
      if (this.winningImage) {
        const scaledWinningWidth = this.winningImage.width() * this.settings.winningImageScale
        const scaledWinningHeight = this.winningImage.height() * this.settings.winningImageScale

        return {
          x: this.settings.winningImageOffset[0] + scaledWinningWidth / 2,
          y: this.settings.winningImageOffset[1] + scaledWinningHeight / 2
        }
      }
      return false
    }

    calculateWinningImageStageZoom = () => {
      if (this.winningImage) {
        const xScale = this.stage.width() / this.winningImage.width() / this.winningImage.scaleX()
        const yScale = this.stage.height() / this.winningImage.height() / this.winningImage.scaleY()
        return Math.min(xScale, yScale) * 0.95 // Add some padding
      }
      return false
    }

    getNormalizedStageCenterCoordinates = () => {
      return {
        x: ((this.stage.width() / 2) - this.stage.x()) / this.stage.scaleX(),
        y: ((this.stage.height() / 2) - this.stage.y()) / this.stage.scaleX()
      }
    }

    calculateScaledStageCenterCoordinates = (normalizedPosition, targetScale = null) => {
      if (!targetScale) {
        targetScale = this.stage.scaleX()
      }
      return {
        x: (this.stage.width() / 2) - normalizedPosition.x * targetScale,
        y: (this.stage.height() / 2) - normalizedPosition.y * targetScale
      }
    }

    checkForSnap = (piece) => {
      this.settings.targets.forEach((target, idx) => {
        const [targetPieceTypeId, targetx, targety, targetRotation] = target
        const distance = this.calculateDistance(piece.x(), piece.y(), targetx, targety)
        const targetDistance = 30
        if (piece.attrs.pieceTypeId == targetPieceTypeId &&
          piece.rotation() == targetRotation &&
          distance < targetDistance &&
          this.snapped[idx] == false) {

          this.snapped[idx] = true
          piece.position({ x: targetx, y: targety })
          piece.listening(false)
          const fill = piece.fill()
          piece.to({
            fill: 'white',
            duration: 0.25,
            onFinish: () => {
              piece.to({
                fill: fill,
                duration: 0.25
              })
            }
          })
        }
      })
      this.checkForWin()
    }

    checkForWin = () => {
      if (this.snapped.reduce((acc, curr) => acc && curr, true)) {
        // let the snap-animation play out before fading in the image
        setTimeout(this.playWinningAnimation, 1000)
      }
    }

    playWinningAnimation = () => {
      const base = {
        duration: 2,
        easing: Konva.Easings.EaseInOut
      }

      this.winningImageLayer.to({
        ...base,
        opacity: 1,
        onFinish: this.showResetButton
      })

      if (this.winningImage) {
        this.tangramPieceLayer.to({
          ...base,
          opacity: 0
        })
        this.targetImageLayer.to({
          ...base,
          opacity: 0
        })
        const targetScale = this.calculateWinningImageStageZoom()
        const normalizedTargetPosition = this.calculateNormalizedWinningImageCenter()
        const stageTargetPosition = this.calculateScaledStageCenterCoordinates(normalizedTargetPosition, targetScale)

        this.stage.to(
          {
            ...base,
            x: stageTargetPosition.x,
            y: stageTargetPosition.y,
            scaleX: targetScale,
            scaleY: targetScale,
          }
        )
      }

      this.showWinningTextButton()
    }

    rotatePiece = (piece) => {
      const rotationMod = tangramPieceArray[piece.attrs.pieceTypeId].rotationMod
      const newRotation = (piece.rotation() + 45) % rotationMod
      piece.listening(false)
      // The animation should always be clockwise (+45) while the internal value should be
      // limited by rotationMod. Set the internal value, after the animation finishes
      piece.to({
        rotation: piece.rotation() + 45,
        duration: 0.1,
        easing: Konva.Easings.EaseInOut,
        onFinish: () => {
          piece.rotation(newRotation)
          piece.listening(true)
        }
      })

      if (this.settings.editor) {
        this.updateTargets()
      }
    }

    fitToBounds = (thing, bounds) => {
      const [minX, minY, maxX, maxY] = bounds
      if (thing.x() < minX) {
        thing.x(minX)
      } else if (thing.x() > maxX) {
        thing.x(maxX)
      }

      if (thing.y() < minY) {
        thing.y(minY)
      } else if (thing.y() > maxY) {
        thing.y(maxY)
      }
    }

    resizeStageToParentContainer = () => {
      this.stage.width(this.offsetWidth)
      this.stage.height(this.offsetHeight)
      this.setDefaultZoom()
      this.setBounds()
    }

    // Zoom Stuff
    zoom = (newZoom) => {
      if (newZoom > 2 * this.defaultZoom) {
        newZoom = 2 * this.defaultZoom
      } else if (newZoom < 0.2 * this.defaultZoom) {
        newZoom = 0.2 * this.defaultZoom
      }

      const oldCenter = this.getNormalizedStageCenterCoordinates()

      this.stage.scale({ x: newZoom, y: newZoom })

      const newCenter = this.calculateScaledStageCenterCoordinates(oldCenter)

      this.stage.position(newCenter)

      this.setBounds()
    }

    wheelZoom = (evt) => {
      evt.evt.preventDefault()
      const zoomBy = 1.05
      // how to scale? Zoom in? Or zoom out?
      let direction = evt.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (evt.evt.ctrlKey) {
        direction = -direction;
      }

      const oldZoom = this.stage.scaleX()

      var newZoom = direction > 0 ? oldZoom * zoomBy : oldZoom / zoomBy;

      this.zoom(newZoom)
    }

    pinchZoom = (evt) => {
      evt.evt.preventDefault()

      var touch1 = evt.evt.touches[0]
      var touch2 = evt.evt.touches[1]
      if (touch1 && touch2) {
        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        if (this.stage.isDragging()) {
          this.stage.stopDrag();
        }
        this.tangramPieceLayer.children.forEach((child) => {
          if (child.isDragging()) {
            child.stopDrag()
          }
        })

        const dist = this.calculateDistance(touch1.clientX, touch1.clientY, touch2.clientX, touch2.clientY);

        if (!this.pinchZoomLastDist) {
          this.pinchZoomLastDist = dist;
        }

        const zoom = this.stage.scaleX() * (dist / this.pinchZoomLastDist);
        this.zoom(Math.min(zoom, 2))
        this.pinchZoomLastDist = dist;
      }
    }

    resetPinchZoom = () => {
      this.pinchZoomLastDist = 0;
    }

    setDefaultZoom = () => {
      // calculate defaultZoomX for either targetImageWidth or tangram-pieces and add 200px padding 
      const defaultZoomX = this.stage.width() / (Math.max(this.targetWidth, 400) + 200)

      // calculate defaultZoomY for targetHeight + tangram-pieces (400px) and add 200px padding and 100 margin inside
      const defaultZoomY = this.stage.height() / (this.targetHeight + 700)

      this.defaultZoom = Math.min(defaultZoomX, defaultZoomY)
    }

    setBounds = () => {
      const zoom = this.stage.scaleX()

      this.stageBounds = [
        (-this.targetWidth + 100) * zoom,
        (-this.targetHeight + 100) * zoom,
        this.stage.width() - (100 * zoom),
        this.stage.height() - (100 * zoom)
      ]

      this.pieceBounds = [
        (-this.stage.width() + 100) / zoom,
        (-this.stage.height() + 100) / zoom,
        this.targetWidth + (this.stage.width() - 100) / zoom,
        this.targetHeight + (this.stage.height() - 100) / zoom
      ]
      this.fitToBounds(this.stage, this.stageBounds)
      this.tangramPieceLayer.children.forEach((piece) => this.fitToBounds(piece, this.pieceBounds))
    }

    calculateDistance = (x1, y1, x2, y2) => {
      // pythagoras x^2 + y^2 = dist^2
      return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
    }

    // Editor Stuff
    setupEditor = () => {
      // Add editor fields
      const targetScaleInput = document.createElement('input')
      targetScaleInput.setAttribute('type', 'number')
      targetScaleInput.setAttribute('step', '0.01')
      targetScaleInput.setAttribute('value', this.settings.targetImageScale)
      targetScaleInput.onchange = (evt) => {
        const scale = evt.target.value
        this.targetImage.scale({ x: scale, y: scale })
        this.settings.targetImageScale = scale
        this.printSettings()
      }
      this.appendChild(targetScaleInput)

      const winningImageVisibleInput = document.createElement('input')
      winningImageVisibleInput.setAttribute('type', 'checkbox')
      winningImageVisibleInput.onchange = (evt) => {
        const visible = evt.target.checked
        this.winningImageLayer.opacity(visible * 0.5)
      }
      this.appendChild(winningImageVisibleInput)

      const winningScaleInput = document.createElement('input')
      winningScaleInput.setAttribute('type', 'number')
      winningScaleInput.setAttribute('step', '0.01')
      winningScaleInput.setAttribute('value', this.settings.winningImageScale)
      winningScaleInput.onchange = (evt) => {
        const scale = evt.target.value

        this.winningImage.scale({ x: scale, y: scale })
        this.settings.winningImageScale = scale
        this.printSettings()
      }
      this.appendChild(winningScaleInput)

      const winningXOffsetInput = document.createElement('input')
      winningXOffsetInput.setAttribute('type', 'number')
      winningXOffsetInput.setAttribute('step', '1')
      winningXOffsetInput.setAttribute('value', this.settings.winningImageOffset[0])
      winningXOffsetInput.onchange = (evt) => {
        const x = evt.target.value

        this.winningImage.x(x)
        this.settings.winningImageOffset[0] = x
        this.printSettings()
      }
      this.appendChild(winningXOffsetInput)

      const winningYOffsetInput = document.createElement('input')
      winningYOffsetInput.setAttribute('type', 'number')
      winningYOffsetInput.setAttribute('step', '1')
      winningYOffsetInput.setAttribute('value', this.settings.winningImageOffset[1])
      winningYOffsetInput.onchange = (evt) => {
        const y = evt.target.value

        this.winningImage.y(y)
        this.settings.winningImageOffset[1] = y
        this.printSettings()
      }
      this.appendChild(winningYOffsetInput)

      const editorOutput = document.createElement('pre')
      this.editorOutput = editorOutput
      this.appendChild(editorOutput)
      this.printSettings()

      this.style.marginBottom = "5rem"

      // Add tangram pieces to the target locations
      this.addTargetPieces()
    }

    addTargetPieces = () => {
      this.settings.targets.forEach((targetDef) => {
        let [typeIdx, x, y, rotation] = targetDef
        let type = tangramPieceArray[typeIdx]
        this.addSingleTangramPiece({
          type: type,
          x: x,
          y: y,
          rotation: rotation
        }, 0, 0, this.updateTargets)
      })
    }

    updateTargets = () => {
      const targets = []
      this.tangramPieceLayer.children.forEach((child) => {
        child.x(Math.floor(child.x()))
        child.y(Math.floor(child.y()))
        targets.push([child.attrs.pieceTypeId, child.x(), child.y(), child.rotation()])
      })
      this.settings.targets = targets
      this.printSettings()
    }

    printSettings = () => {
      this.editorOutput.innerHTML = JSON.stringify(this.settings)
    }
  }
);
