// 1x1px transparent gif as placeholder while loading
const invisibleImage = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
const gestureHandlingText = {
    touch: "Verschieben der Grafik mit zwei Fingern",
    scroll: "Verwende Strg+Scrollen zum Zoomen der Grafik",
    scrollMac: "Verwende \u2318+Scrollen zum Zoomen der Grafik"
};

import * as floodfill from './floodfill'

/* This params can all be overriden via the params parameter from Elm or via a 
`config`-Object encoded as JSON-string (as used by the `tangram` content type).
This constant is also used as documentation so please make sure there is a default set for
every possible option.
*/
const defaultParams = {
    // The width and height of the canvas. With a bigger canvas there are less artifacts,
    // but the floodfill needs more time. Choose a good middle ground.
    canvasWidth: 1334,
    canvasHeight: 1084,
    // Url to the image of the outline.
    outlineImageUrl: "/windowcolor/schleswig.svg",
    // This coords will be flood filled with a transparent black. Those areas will look as 
    // all the others ,but won't be fillable by the user.
    unfillableCoords: [[0, 0], [1359, 0]]
  }

  
window.customElements.define(
    "window-color",
    class WindowColor extends HTMLElement {
        connectedCallback() {
            let config = {}
            if (this.config) {
                try {
                    config = JSON.parse(this.config)
                } catch (e) {
                    // This can only be a syntax error. Print it to the console, but don't fail.
                    console.error(e)
                }
            }

            this.settings = { ...defaultParams, ...config, ...this.params }
            const {canvasWidth, canvasHeight} = this.settings
            const imageBounds = [[0, 0], [canvasHeight, canvasWidth]]

            // create invisible canvas for the drawing 
            const canvas = document.createElement('canvas')
            canvas.setAttribute('width', canvasWidth)
            canvas.setAttribute('height', canvasHeight)

            // create leaflet-container and init it
            const leafletEl = document.createElement('div')
            this.appendChild(leafletEl)

            const leaflet = L.map(leafletEl, {
                crs: L.CRS.Simple,
                minZoom: -5,
                zoomControl: false,
                gestureHandling: true,
                gestureHandlingText: gestureHandlingText,
                doubleClickZoom: false,
                attributionControl: false
            });

            const imageOverlay = L.imageOverlay(invisibleImage, imageBounds).addTo(leaflet);

            L.control
                .zoom({
                    position: "topright"
                })
                .addTo(leaflet);

            // setup initial view
            leaflet.fitBounds(imageBounds);
            leaflet.panTo([-10000, canvasWidth / 2]);

            // max outer bounds stuff
            this.calculateOuterBounds(leaflet, imageOverlay);
            leaflet.setMaxBounds(this.outerBounds);

            window.addEventListener("resize", () => {
                this.calculateOuterBounds(leaflet, imageOverlay)
                leaflet.setMaxBounds(this.outerBounds);
            })

            leaflet.on("zoomend", () => {
                this.calculateOuterBounds(leaflet, imageOverlay)
                leaflet.setMaxBounds(this.outerBounds);
            })

            leaflet.on("drag", () => {
                leaflet.panInsideBounds(this.outerBounds, { animate: false });
            })

            // add event handler for flood filling to leaflet
            leaflet.on("click", (e) => {
                const latlon = leaflet.mouseEventToLatLng(e.originalEvent)
                const canvasX = Math.floor(latlon.lng)
                // latitude is the reverse of y-coordinate
                const canvasY = canvasHeight - Math.floor(latlon.lat)
                // clicks can be outside of canvas where filling is not possible
                if (canvasX >= 0 && canvasX <= canvasWidth && canvasY >= 0 && canvasY <= canvasHeight) {
                    let color = hexToRgba(this.dataset.color)
                    floodfill.floodFill(canvas, canvasX, canvasY, color)
                    imageOverlay.setUrl(canvas.toDataURL("image/png"))
                }
            })

            // load the outline image and draw the first canvas
            const outlineImage = new Image()
            outlineImage.onload = () => {
                floodfill.init(canvas, outlineImage)

                // init unfillable places with a transparent black
                this.settings.unfillableCoords.forEach(([x, y]) => {
                    floodfill.floodFill(canvas, x, y, { r: 0, g: 0, b: 0, a: 0})
                })

                imageOverlay.setUrl(canvas.toDataURL("image/png"))
            }
            outlineImage.src = this.settings.outlineImageUrl

            // add the button for downloading the image
            const downloadButton = document.createElement('button')
            downloadButton.setAttribute('class', 'btn btn-primary m-2')
            downloadButton.innerText = "Bild speichern"

            downloadButton.addEventListener('click', () => {
                // export image from canvas
                const url = canvas.toDataURL("image/png");

                const image = new Image();
                image.src = url;

                // trigger download
                const a = document.createElement("a");
                a.download = "Ihr persönliches Kirchenfenster.png";
                a.href = url.replace("image/png", "image/octet-stream");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })

            this.appendChild(downloadButton)
        }

        /* this function calculates the outer bounds for leaflet
           so that the image is not centered, but can be moved around freely.
           The bounds are in coordinates and not in pixels. This makes the 
           calculation more complicated and has to be recalculated on every 
           zoom change and browser resize
        */
        calculateOuterBounds(leaflet, imageOverlay) {
            const leafletWidth = leaflet.getContainer().clientWidth;
            const leafletHeight = leaflet.getContainer().clientHeight;
            const imageWidth = imageOverlay.getElement().width
            const imageHeight = imageOverlay.getElement().height
            const {canvasWidth, canvasHeight} = this.settings

            let minWidth
            let maxWidth = canvasWidth
            let minHeight
            let maxHeight = canvasHeight
            if (imageWidth > leafletWidth) {
                minWidth = 0
                maxWidth = canvasWidth
            } else {
                minWidth = (imageWidth - leafletWidth) * canvasWidth / imageWidth
                maxWidth = leafletWidth * canvasWidth / imageWidth
            }

            if (imageHeight > leafletHeight) {
                minHeight = 0
                maxHeight = canvasHeight
            } else {
                minHeight = (imageHeight - leafletHeight) * canvasHeight / imageHeight
                maxHeight = leafletHeight * canvasHeight / imageHeight
            }

            this.outerBounds = [[minHeight, minWidth], [maxHeight, maxWidth]]
        }
    }
);


// hexToRgba © https://stackoverflow.com/users/80860/kennebec
const hexToRgba = function (hex) {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        let c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 255 };
    }
    throw new Error('Bad Hex');
}
