window.customElements.define(
    "tower-game-helper",
    class TowerGameHelper extends HTMLElement {
        async connectedCallback() {
            this.images.map((src) => this.loadImage(src));

            requestAnimationFrame(() => {
                this.heightChanged()
                window.addEventListener('resize', () => this.heightChanged())
            });
        }

        heightChanged() {
            this.dispatchEvent(new CustomEvent("heightChanged", {
                detail: {
                    canvasHeight: document.getElementById(this.towerGameId).clientHeight
                }
            }))
        }
        loadImage(src) {
            var img = new Image();
            img.onload = () => {
                this.dispatchEvent(
                    // BEWARE! elm silently fails in case it can't decode the event's data.
                    new CustomEvent("imageLoaded", {
                        detail: {
                            url: img.src,
                            height: img.height,
                            width: img.width
                        },
                    })
                );
            };
            img.src = src;
        }
    }
);

export const render = async (images) => {
    const imgs = await Promise.all(images.map(loadImage));
    const smallestWidth = Math.min(...imgs.map((i) => i.width));

    // draw on canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    let y = 0;
    canvas.width = smallestWidth;
    canvas.height = imgs.reduce(
        (acc, e) => acc + e.height * (smallestWidth / e.width),
        0
    );
    imgs.forEach((img) => {
        ctx.drawImage(
            img,
            0,
            y,
            smallestWidth,
            img.height + img.width / smallestWidth
        );
        y = y + img.height;
    });

    // export image from canvas
    const url = canvas.toDataURL("image/png");

    const image = new Image();
    image.src = url;

    // trigger download
    const a = document.createElement("a");
    a.download = "Ihr persönlicher Turm.png";
    a.href = url.replace("image/png", "image/octet-stream");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

const loadImage = (src) =>
    new Promise((resolve, _) => {
        var img = new Image();
        img.onload = function () {
            resolve(this);
        };
        img.src = src;
    });
