export const render = (params) => {
    params = params || {}

    let imageIds = params.imageIds || []
    let outfile = params.outfile || "Ihre persönliche Grafik"

    // draw on canvas
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")

    let canvasWidth = 0
    let canvasHeight = 0

    imageIds.forEach((val, idx) => {
        const img = document.getElementById(val)
        canvasWidth = Math.max(canvasWidth, img.naturalWidth)
        canvasHeight += img.naturalHeight
    })

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    let currentHeight = 0

    imageIds.forEach((val, idx) => {
        const img = document.getElementById(val)
        ctx.drawImage(img, 0, currentHeight, img.naturalWidth, img.naturalHeight)
        currentHeight += img.naturalHeight
    })

    const url = canvas.toDataURL("image/png")

    const image = new Image()
    image.src = url

    // trigger download
    const a = document.createElement("a")
    a.download = outfile + ".png"
    a.href = url.replace("image/png", "image/octet-stream")
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}
