import "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
const gestureHandlingText = {
  touch: "Verschieben des Grundrisses mit zwei Fingern",
  scroll: "Verwende Strg+Scrollen zum Zoomen des Grundrisses",
  scrollMac: "Verwende \u2318+Scrollen zum Zoomen des Grundrisses"
};

window.customElements.define(
  "leaflet-groundplan",
  class LeafletGroundplan extends HTMLElement {
    connectedCallback() {
      const groundplan = L.map(this, {
        crs: L.CRS.Simple,
        minZoom: -5, //TODO: Calculate minZoom from GroundplanSize or something
        zoomControl: false,
        attributionControl: false,
        gestureHandling: true,
        gestureHandlingText: gestureHandlingText
      });

      L.control
        .zoom({
          position: "topright"
        })
        .addTo(groundplan);

      const bounds = [
        [0, 0],
        [1000, 1000]
      ];

      L.imageOverlay(this.params.groundplanImageUrl, bounds).addTo(groundplan);
      groundplan.fitBounds(bounds);
      groundplan.setZoom(groundplan.getZoom() + 1, { animate: false });

      groundplan.setMaxBounds(bounds);
      groundplan.on("drag", function () {
        groundplan.panInsideBounds(bounds, { animate: false });
      });

      const groundplanMarkers = L.featureGroup();
      this.params.pois.forEach((poi, index) => {
        const groundplanIcon = L.divIcon({
          className: "groundplan-marker",
          html: index + 1,
          iconSize: [24, 24]
        });
        const poiMarker = L.marker([poi.yCoord, poi.xCoord], {
          icon: groundplanIcon
        }).addTo(groundplan);

        poiMarker.on("click", () => document._app.ports.groundplanPoiSelected.send(poi.id));

        groundplanMarkers.addLayer(poiMarker);
      });
      groundplan.addLayer(groundplanMarkers);
    }
  }
);
