import "konva";
import Konva from "konva";

/* This params can all be overriden via the params parameter from Elm or via a 
`config`-Object encoded as JSON-string (as used by the `searching game` content type).
This constant is also used as documentation so please make sure there is a default set for
every possible option.
*/
const defaultParams = {
  // The coordinates of the mistakes
  // targetX in percentage of the width and targetY in percentage of the height
  winningCoordinatesNew: [
    { x: 9.9, y: 55 },
    { x: 43.3, y: 41.65 },
    { x: 55.4, y: 28.65 },
    { x: 39.75, y: 66 },
    { x: 51.8, y: 66 },
    { x: 95.6, y: 89 },
  ],
  // Is the game in editor-mode (to create the json-config string)
  editor: false,
  // The original image without mistakes to compare to
  originalToCompareImageUrl: "/searchingGame/original.jpg",
  // The image with mistakes
  targetImageUrl: "/searchingGame/Oldenswort-Altar.jpg",
  imageAspectRatio: 2322 / 4690,
  // the image to be shown after successful completion
  // optional: set to false when no image should be shown.
  winningImageUrl: "/searchingGame/original.jpg",
  // the x and y offset of the winning images top left corner
  winningImageOffset: [0, 0],
};

window.customElements.define(
  "searching-game",
  class SearchingGame extends HTMLElement {
    connectedCallback() {
      let config = {};
      if (this.config) {
        try {
          config = JSON.parse(this.config);
        } catch (e) {
          // This can only be a syntax error. Print it to the console, but don't fail.
          console.error(e);
        }
      }

      this.settings = { ...defaultParams, ...config, ...this.params };

      this.defaultWidth = 1000;

      this.circles = [];

      this.found = Array.from(
        { length: this.settings.winningCoordinatesNew.length },
        () => false
      );
      const searchingGameElement =
        document.getElementsByTagName("searching-game");

      if (searchingGameElement.length > 0) {
        this.defaultWidth =
          searchingGameElement[0].getBoundingClientRect().width;
        searchingGameElement[0].setAttribute(
          "style",
          `height:${this.defaultWidth * this.settings.imageAspectRatio * 2}px`
        );
      }
      // this data gives the position and size of the frame
      this.data = {
        frameGroup: {
          x: 0,
          y: 0,
          width: this.defaultWidth,
          height: this.defaultWidth * this.settings.imageAspectRatio,
        },
        frameGroupOriginal: {
          x: 0,
          y: this.defaultWidth * this.settings.imageAspectRatio,
          width: this.defaultWidth,
          height: this.defaultWidth * this.settings.imageAspectRatio,
        },
      };

      this.pinchZoomLastDist = 0;

      this.resetPinchZoom();

      // Needed for pinchZoom
      Konva.hitOnDragEnabled = true;

      // add a stage
      this.stage = new Konva.Stage({
        container: this,
        width: this.defaultWidth,
        height: this.defaultWidth * this.settings.imageAspectRatio * 2,
      });

      // Add a layer and group to draw on
      this.layer = new Konva.Layer({});
      this.group = new Konva.Group({ clip: this.data.frameGroup });
      this.innerGroup = new Konva.Group({ draggable: true });
      this.rect = new Konva.Rect(this.data.frameGroup);
      this.imageObj = new Image();

      this.originalImageToCompareLayer = new Konva.Layer({});
      this.originalGroup = new Konva.Group({
        clip: this.data.frameGroupOriginal,
      });
      this.originalInnerGroup = new Konva.Group({ draggable: true });
      this.originalRect = new Konva.Rect(this.data.frameGroupOriginal);
      this.originalImage = new Konva.Image({ draggable: false });
      this.originalImageObj = new Image();

      this.winningImageLayer = new Konva.Layer({
        opacity: 0,
        listening: false,
      });
      this.winningImage = undefined;
      this.image = new Konva.Image({ draggable: false });

      this.currentPositionX = 0;
      this.currentPositionY = 0;
      this.movementStartX = undefined;
      this.movementStartY = undefined;

      this.resetButton = document.createElement("button");

      this.movementX = 0;
      this.movementY = 0;

      this.stage.add(this.layer);
      this.stage.add(this.winningImageLayer);
      this.stage.add(this.originalImageToCompareLayer);

      // Use the html image object to load the image and handle when laoded.
      this.imageObj.onload = () => this.setup();
      this.imageObj.src = this.settings.targetImageUrl;

      this.originalImageObj.onload = () => this.setup2();
      this.originalImageObj.src = this.settings.originalToCompareImageUrl;

      // Initialize event handlers
      this.group.on("wheel", (evt) => this.wheelZoom(evt));
      this.originalGroup.on("wheel", (evt) => this.wheelZoom(evt));

      this.group.on("touchmove", (evt) => this.pinchZoom(evt, false));
      this.group.on("touchend", () => this.resetPinchZoom());
      this.originalGroup.on("touchmove", (evt) => this.pinchZoom(evt, true));
      this.originalGroup.on("touchend", () => this.resetPinchZoom());

      this.resizeStageToParentContainer();
      window.addEventListener("resize", this.resizeStageToParentContainer);

      this.resetButton.innerText = "Suchspiel neu starten";
      this.resetButton.setAttribute("class", "btn btn-primary reset-btn");
      this.resetButton.onclick = this.reset;
      this.hideResetButton();
      this.appendChild(this.resetButton);

      // Load winningImage asynchronously if set
      if (this.settings.winningImageUrl) {
        const winningImageEl = new Image();
        winningImageEl.onload = () =>
          this.handleLoadedWinningImage(winningImageEl);
        winningImageEl.src = this.settings.winningImageUrl;
      }
    }

    disconnectedCallback() {
      window.removeEventListener("resize", this.resizeStageToParentContainer);
    }

    // simple function to get the x & y for the image to be centered in the frame
    getMiddlePos(pos, size, imagesize) {
      return {
        x: pos.x + (size.width - imagesize.width) / 2,
        y: pos.y + (size.height - imagesize.height) / 2,
      };
    }

    handleLoadedWinningImage(winningImageEl) {
      this.winningImage = new Konva.Image({
        image: winningImageEl,
        x: this.settings.winningImageOffset[0],
        y: this.settings.winningImageOffset[1],
        width: this.stage.width(),
        height: this.stage.width() * this.settings.imageAspectRatio,
      });
      this.winningImageLayer.add(this.winningImage);
    }

    setupGroupElements(
      layer,
      group,
      rect,
      innerGroup,
      image,
      imageObj,
      frameGroup,
      upperOrBottom
    ) {
      layer.add(group);
      group.add(rect);
      group.add(innerGroup);
      image.image(imageObj); // set the Konva image content to the html image content

      rect.listening(false);
      // compute image position so that it is initially centered in the frame
      let imagePos = this.getMiddlePos(
        frameGroup,
        {
          width: this.stage.width(),
          height: this.stage.width() * this.settings.imageAspectRatio,
        },
        {
          width: this.stage.width(),
          height: imageObj.height * (this.stage.width() / imageObj.width),
        }
      );

      innerGroup.setAttrs({
        x: imagePos.x,
        y: 0,
        width: imageObj.width * (this.stage.width() / imageObj.width),
        height: imageObj.height * (this.stage.width() / imageObj.width),

        // This function ensures the oversized image cannot be dragged beyond frame edges.
        // Is is firect by the drag event.
        dragBoundFunc: (pos) => this.dragBoundFuncExter(pos, upperOrBottom),
      });

      image.setAttrs({
        x: 0,
        y: 0,
        width: imageObj.width,
        height: imageObj.height,
        scaleX: this.stage.width() / imageObj.width,
        scaleY: this.stage.width() / imageObj.width,
      });

      innerGroup.add(image); // add the image to the frame group
      image.moveToBottom(); // ensure the frame rect is above the image in the z-index.
    }

    resizeStageToParentContainer = () => {
      const searchingGameElement =
        document.getElementsByTagName("searching-game");
      let currentWidth = 0,
        currentHeight = 0;
      if (searchingGameElement.length > 0) {
        currentWidth = searchingGameElement[0].getBoundingClientRect().width;
        currentHeight = currentWidth * this.settings.imageAspectRatio * 2;
        searchingGameElement[0].setAttribute(
          "style",
          `height:${currentHeight}px`
        );
      }

      if (this.winningImage) {
        this.winningImage.height(
          this.stage.width() * this.settings.imageAspectRatio
        );
        this.winningImage.width(this.stage.width());
      }

      this.stage.setAttrs({
        width: currentWidth,
        height: currentHeight,
      });

      const middlePOS = this.getMiddlePos(
        { x: 0, y: 0 },
        {
          width: currentWidth,
          height:
            this.imageObj.height * (this.stage.width() / this.imageObj.width),
        },
        {
          width:
            this.imageObj.width *
            (this.stage.width() / this.imageObj.width) *
            this.innerGroup.scaleX(),
          height:
            this.imageObj.height *
            (this.stage.width() / this.imageObj.width) *
            this.innerGroup.scaleY(),
        }
      );

      this.rect.width(this.stage.width());
      this.rect.height(currentWidth * this.settings.imageAspectRatio);
      this.rect.y(0);

      this.group.setAttrs({
        width: currentWidth,
        clipWidth: currentWidth,
        height: currentWidth * this.settings.imageAspectRatio,
        clipHeight: currentWidth * this.settings.imageAspectRatio,
        y: 0,
        clipY: 0,
      });

      this.layer.setAttrs({
        y: 0,
      });

      this.originalRect.width(this.stage.width());
      this.originalRect.height(currentWidth * this.settings.imageAspectRatio);
      this.originalRect.y(0);

      this.originalGroup.setAttrs({
        width: currentWidth,
        clipWidth: currentWidth,
        height: currentWidth * this.settings.imageAspectRatio,
        clipHeight: currentWidth * this.settings.imageAspectRatio,
        y: 0,
        clipY: 0,
      });

      this.originalImageToCompareLayer.setAttrs({
        y: currentWidth * this.settings.imageAspectRatio,
      });

      this.innerGroup.setAttrs({
        x: middlePOS.x,
        y: 0,
        width: this.stage.width(),
        height:
          this.imageObj.height * (this.stage.width() / this.imageObj.width),
      });

      this.originalInnerGroup.setAttrs({
        x: middlePOS.x,
        y: 0,
        width: this.stage.width(),
        height:
          this.imageObj.height * (this.stage.width() / this.imageObj.width),
      });

      this.image.setAttrs({
        width: this.imageObj.width,
        height: this.imageObj.height,
        scaleX: this.stage.width() / this.imageObj.width,
        scaleY:
          (this.stage.width() * this.settings.imageAspectRatio) /
          this.imageObj.height,
      });

      this.originalImage.setAttrs({
        width: this.imageObj.width,
        height: this.imageObj.height,
        scaleX: this.stage.width() / this.imageObj.width,
        scaleY:
          (this.stage.width() * this.settings.imageAspectRatio) /
          this.imageObj.height,
      });

      this.circles.forEach((circle, index) => {
        circle.setAttrs({
          x:
            (this.stage.width() / 100) *
            this.settings.winningCoordinatesNew[index].x,
          y:
            ((this.stage.width() * this.settings.imageAspectRatio) / 100) *
            this.settings.winningCoordinatesNew[index].y,
        });
      });
    };

    dragBoundFuncExter(pos, upperOrBottom) {
      const isUpper = upperOrBottom === "upper";

      const frameGroupInternal = isUpper
        ? this.data.frameGroup
        : this.data.frameGroupOriginal;

      const innerGroupInternal = isUpper
        ? this.innerGroup
        : this.originalInnerGroup;

      const parnterInnerGroup = isUpper
        ? this.originalInnerGroup
        : this.innerGroup;

      const imagePos = innerGroupInternal.getClientRect(); // get the image dimensions.

      const maxPos = {
        // compute max x & y position allowed for image
        x: frameGroupInternal.x,
        y: frameGroupInternal.y,
      };

      const minPos = {
        x: frameGroupInternal.x + (this.stage.width() - imagePos.width),
        y: frameGroupInternal.y + frameGroupInternal.height - imagePos.height,
      };

      let newX = pos.x >= maxPos.x ? maxPos.x : pos.x; // ensure left edge not within frame
      let newY = pos.y >= maxPos.y ? maxPos.y : pos.y; // ensure top edge not within frame
      newX = newX < minPos.x ? minPos.x : newX; // ensure right edge not within frame
      newY = newY < minPos.y ? minPos.y : newY; // ensure top edge not within frame

      this.currentPositionX = newX;
      this.currentPositionY = newY;

      parnterInnerGroup.position({
        x: newX,
        y:
          upperOrBottom === "upper"
            ? newY
            : newY - this.stage.width() * (2322 / 4960),
      });

      // dragBoundFunc must return a value with x & y. Either return same value passed in
      // or modify the value.
      return {
        x: newX,
        y: newY,
      };
    }

    /* ---------------------------- */
    /* ------- SETUP START -------- */
    /* -----------------------------*/

    setHitTargets() {
      this.settings.winningCoordinatesNew.forEach((coOrd) => {
        var circle = new Konva.Circle({
          x: (this.stage.width() / 100) * coOrd.x,
          y:
            ((this.stage.width() * this.settings.imageAspectRatio) / 100) *
            coOrd.y,
          radius: 20,
          fill: "transparent",
        });

        this.circles = this.circles.concat([circle]);

        circle.on("click", (evt) => {
          evt.target.stroke("#00ff00");
          evt.target.strokeWidth(3);
          evt.target.opacity(1);
          this.checkForErrorFound(evt);
        });

        circle.on("tap", (evt) => {
          evt.target.stroke("#00ff00");
          evt.target.strokeWidth(3);
          evt.target.opacity(1);
          this.checkForErrorFound(evt);
        });

        this.innerGroup.add(circle);
      });
    }

    setup() {
      this.found = Array.from(
        { length: this.settings.winningCoordinatesNew.length },
        () => false
      );

      this.setupGroupElements(
        this.layer,
        this.group,
        this.rect,
        this.innerGroup,
        this.image,
        this.imageObj,
        this.data.frameGroup,
        "upper"
      );

      if (this.settings.editor) {
        this.setupEditor();
      } else {
        this.setHitTargets();

        this.innerGroup.on("dragstart", (evt) => {
          if (this.movementStartX === undefined) {
            this.movementStartX = this.innerGroup.x();
          }
          if (this.movementStartY === undefined) {
            this.movementStartY = this.innerGroup.y();
          }
        });
      }
    }

    setup2() {
      this.setupGroupElements(
        this.originalImageToCompareLayer,
        this.originalGroup,
        this.originalRect,
        this.originalInnerGroup,
        this.originalImage,
        this.originalImageObj,
        this.data.frameGroupOriginal,
        "bottom"
      );
    }

    /* ---------------------------- */
    /* -------- SETUP END --------- */
    /* ---------------------------- */

    /* ---------------------------- */
    /* ---- RESET BUTTON START ---- */
    /* ---------------------------- */

    hideResetButton() {
      this.resetButton.style.display = "none";
    }

    showResetButton = () => {
      this.resetButton.style.display = "block";
    };

    reset = () => {
      this.circles = [];
      this.winningImageLayer.opacity(0);
      this.layer.removeChildren();
      this.layer.opacity(1);
      this.originalImageToCompareLayer.removeChildren();
      this.originalImageToCompareLayer.opacity(1);
      this.hideResetButton();
      this.innerGroup.scale({ x: 1, y: 1 });
      this.innerGroup.removeChildren();
      this.originalInnerGroup.scale({ x: 1, y: 1 });
      this.setup();
      this.setup2();
    };

    /* ---------------------------- */
    /* ----- RESET BUTTON END ----- */
    /* -----------------------------*/

    /* ---------------------------- */
    /* ----- ZOOM STUFF START ----- */
    /* ---------------------------- */

    wheelZoom(evt) {
      evt.evt.preventDefault();
      const zoomBy = 1.05;

      // how to scale? Zoom in? Or zoom out?
      let direction = evt.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (evt.evt.ctrlKey) {
        direction = -direction;
      }

      const oldZoom = this.innerGroup.scaleX();
      const newZoom = direction > 0 ? oldZoom * zoomBy : oldZoom / zoomBy;

      this.zoom(newZoom, this.innerGroup.scaleX());
    }

    pinchZoom(evt, isOriginal) {
      evt.evt.preventDefault();

      var touch1 = evt.evt.touches[0];
      var touch2 = evt.evt.touches[1];

      if (touch1 && touch2) {
        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        if (this.innerGroup.isDragging()) {
          this.innerGroup.stopDrag();
        }

        if (this.originalInnerGroup.isDragging()) {
          this.originalInnerGroup.stopDrag();
        }

        const dist = this.calculateDistance(
          touch1.clientX,
          touch1.clientY,
          touch2.clientX,
          touch2.clientY
        );

        if (!this.pinchZoomLastDist) {
          this.pinchZoomLastDist = dist;
        }

        const zoomFactor = isOriginal
          ? this.originalInnerGroup.scaleX() * (dist / this.pinchZoomLastDist)
          : this.innerGroup.scaleX() * (dist / this.pinchZoomLastDist);
        let calculateZOOM = Math.min(zoomFactor, 5);

        this.zoom(calculateZOOM, this.innerGroup.scaleX());

        this.pinchZoomLastDist = dist;
      }
    }

    resetPinchZoom() {
      this.pinchZoomLastDist = 0;
    }

    zoom(zoom, oldZoom) {
      let newZoom = zoom;

      if (newZoom < 1) {
        newZoom = 1;
      }

      if (newZoom > 5) {
        newZoom = 5;
      }

      let currentMovement = 0;
      let currentMovementY = 0;

      if (this.movementStartX) {
        currentMovement =
          (this.currentPositionX - this.movementStartX) / oldZoom;
        this.movementX = this.movementX + currentMovement;
      }

      if (this.movementStartY) {
        currentMovementY =
          (this.currentPositionY - this.movementStartY) / oldZoom;
        this.movementY = this.movementY + currentMovementY;
      }

      this.movementStartX = undefined;
      this.movementStartY = undefined;

      this.innerGroup.position({ x: 0, y: 0 });
      this.originalInnerGroup.position({
        x: 0,
        y: 0,
      });

      this.innerGroup.scale({ x: newZoom, y: newZoom });
      this.originalInnerGroup.scale({ x: newZoom, y: newZoom });

      if (newZoom === 1) {
        this.movementX = 0;
        this.movementY = 0;
      }

      const imageMiddle = (this.innerGroup.width() * newZoom) / 2;
      const stageWidth = this.stage.width() / 2;

      const calculatedxPosUpper =
        stageWidth - imageMiddle + this.movementX * newZoom;

      const calculatedYPosUpper =
        (this.stage.width() * this.settings.imageAspectRatio -
          this.innerGroup.height() * newZoom) /
          2 +
        this.movementY * newZoom;

      this.innerGroup.position({
        x:
          calculatedxPosUpper > 0
            ? 0
            : calculatedxPosUpper <
              this.stage.width() - this.innerGroup.width() * newZoom
            ? this.stage.width() - this.innerGroup.width() * newZoom
            : calculatedxPosUpper,
        y:
          calculatedYPosUpper > 0
            ? 0
            : calculatedYPosUpper <
              this.stage.width() * this.settings.imageAspectRatio -
                this.innerGroup.height() * newZoom
            ? this.stage.width() * this.settings.imageAspectRatio -
              this.innerGroup.height() * newZoom
            : calculatedYPosUpper,
      });

      this.originalInnerGroup.position({
        x:
          calculatedxPosUpper > 0
            ? 0
            : calculatedxPosUpper <
              this.stage.width() - this.innerGroup.width() * newZoom
            ? this.stage.width() - this.innerGroup.width() * newZoom
            : calculatedxPosUpper,
        y:
          calculatedYPosUpper > 0
            ? 0
            : calculatedYPosUpper <
              this.stage.width() * this.settings.imageAspectRatio -
                this.innerGroup.height() * newZoom
            ? this.stage.width() * this.settings.imageAspectRatio -
              this.innerGroup.height() * newZoom
            : calculatedYPosUpper,
      });
    }

    calculateDistance(x1, y1, x2, y2) {
      // pythagoras x^2 + y^2 = dist^2
      return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    }

    /* ---------------------------- */
    /* ------ ZOOM STUFF END ------ */
    /* ---------------------------- */

    /* ---------------------------- */
    /* --------- WINNING ---------- */
    /* ---------------------------- */

    checkForWin() {
      if (this.found.reduce((acc, curr) => acc && curr, true)) {
        this.playWinningAnimation();
      }
    }

    playWinningAnimation() {
      const base = {
        duration: 2,
        easing: Konva.Easings.EaseInOut,
      };

      this.winningImageLayer.to({
        ...base,
        opacity: 1,
        onFinish: this.showResetButton,
      });

      if (this.winningImage) {
        this.layer.to({
          ...base,
          opacity: 0,
        });

        this.originalImageToCompareLayer.to({
          ...base,
          opacity: 0,
        });
      }
    }

    checkForErrorFound(evt) {
      const xCoordinate = evt.target.attrs.x;
      const yCoordinate = evt.target.attrs.y;

      this.settings.winningCoordinatesNew.forEach((coOrd, index) => {
        const transformedPercentageX = (this.stage.width() / 100) * coOrd.x;
        const transfomredPercentageY =
          ((this.stage.width() * this.settings.imageAspectRatio) / 100) *
          coOrd.y;

        if (
          transformedPercentageX === xCoordinate &&
          transfomredPercentageY === yCoordinate
        ) {
          this.found[index] = true;
        }
      });

      this.checkForWin();
    }

    /* ---------------------------- */
    /* ------- WINNING END -------- */
    /* ---------------------------- */

    /* ---------------------------- */
    /* ------- EDITOR START ------- */
    /* ---------------------------- */

    setupEditor = () => {
      this.settings.winningCoordinatesNew.forEach((coOrd, index) => {
        const drawncircle = new Konva.Circle({
          x: (this.stage.width() / 100) * coOrd.x,
          y:
            ((this.stage.width() * this.settings.imageAspectRatio) / 100) *
            coOrd.y,
          radius: 15,
          fill: "transparent",
          opacity: 1,
          stroke: "#ffa500",
          strokeWidth: 3,
        });

        this.layer.add(drawncircle);

        const coOrdInputX = document.createElement("input");
        const coOrdInputY = document.createElement("input");
        coOrdInputX.setAttribute("type", "number");
        coOrdInputY.setAttribute("type", "number");
        coOrdInputX.setAttribute("step", "0.1");
        coOrdInputY.setAttribute("step", "0.1");
        coOrdInputX.setAttribute(
          "value",
          this.settings.winningCoordinatesNew[index].x
        );
        coOrdInputY.setAttribute(
          "value",
          this.settings.winningCoordinatesNew[index].y
        );

        coOrdInputX.onchange = (evt) => {
          drawncircle.move({
            x:
              Number(evt.target.value) * (this.stage.width() / 100) -
              this.settings.winningCoordinatesNew[index].x *
                (this.stage.width() / 100),
            y: 0,
          });

          this.settings.winningCoordinatesNew[index] = {
            x: Number(evt.target.value),
            y: this.settings.winningCoordinatesNew[index].y,
          };

          this.printSettings();
        };

        coOrdInputY.onchange = (evt) => {
          drawncircle.move({
            x: 0,
            y:
              Number(evt.target.value) *
                ((this.stage.width() * this.settings.imageAspectRatio) / 100) -
              this.settings.winningCoordinatesNew[index].y *
                ((this.stage.width() * this.settings.imageAspectRatio) / 100),
          });

          this.settings.winningCoordinatesNew[index] = {
            x: this.settings.winningCoordinatesNew[index].x,
            y: Number(evt.target.value),
          };

          this.printSettings();
        };

        this.appendChild(coOrdInputX);
        this.appendChild(coOrdInputY);
      });

      const editorOutput = document.createElement("pre");
      this.editorOutput = editorOutput;
      this.appendChild(editorOutput);
      this.printSettings();
    };

    printSettings = () => {
      this.editorOutput.innerHTML = JSON.stringify(this.settings);
    };

    /* ---------------------------- */
    /* ------- EDITOR END --------- */
    /* ---------------------------- */
  }
);
