// Copyright 2010 William Malone (www.williammalone.com)
//
// Licensed under the Apache License, Version 2.0 (the "License")
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/*jslint browser: true */

export const floodFill = function (canvas, x, y, color) {
    const context = canvas.getContext('2d')
    const pixels = canvas.pixels
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    const outlineImage = canvas.outlineImage

    var pixelStack = [{ x: x, y: y }]
    var linearCoords = (y * canvasWidth + x) * 4
    var startColor = buildColor(linearCoords, pixels.data)

    if (isMatchingColor(color, startColor) || isOutlineColor(startColor)) {
        return
    }

    while (pixelStack.length > 0) {
        var nextPixel = pixelStack.shift()
        var x = nextPixel.x
        var y = nextPixel.y


        var linearCoords = (y * canvasWidth + x) * 4
        while (y-- >= 0 &&
            isMatchingColor(buildColor(linearCoords, pixels.data), startColor)) {
            linearCoords -= canvasWidth * 4
        }
        linearCoords += canvasWidth * 4
        y++

        var reachedLeft = false
        var reachedRight = false
        while (y++ < canvasHeight &&
            isMatchingColor(buildColor(linearCoords, pixels.data), startColor)) {
            pixels.data[linearCoords] = color.r
            pixels.data[linearCoords + 1] = color.g
            pixels.data[linearCoords + 2] = color.b
            pixels.data[linearCoords + 3] = color.a

            if (x > 0) {
                if (isMatchingColor(buildColor(linearCoords - 4, pixels.data), startColor)) {
                    if (!reachedLeft) {
                        pixelStack.push({ x: x - 1, y: y })
                        reachedLeft = true
                    }
                } else if (reachedLeft) {
                    reachedLeft = false
                }
            }

            if (x < canvasWidth - 1) {
                if (isMatchingColor(buildColor(linearCoords + 4, pixels.data), startColor)) {
                    if (!reachedRight) {
                        pixelStack.push({ x: x + 1, y: y })
                        reachedRight = true
                    }
                } else if (reachedRight) {
                    reachedRight = false
                }
            }
            linearCoords += canvasWidth * 4
        }
    }
    context.putImageData(pixels, 0, 0)
    // Redraw outline over pixel array for better smoothness on the edges
    context.drawImage(outlineImage, 0, 0, canvasWidth, canvasHeight)
}

const isMatchingColor = function (color, startColor) {
    return (color.r == startColor.r && color.g == startColor.g && color.b == startColor.b && color.a == startColor.a)
}

const isOutlineColor = function (color) {
    // Black can't be filled, even if it's transparent.
    return (color.r == 0 && color.g == 0 && color.b == 0)
}

const buildColor = function (linearCoords, pixelData) {
    return {
        r: pixelData[linearCoords],
        g: pixelData[linearCoords + 1],
        b: pixelData[linearCoords + 2],
        a: pixelData[linearCoords + 3]
    }
}

export const init = function (canvas, outlineImage) {
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    const context = canvas.getContext("2d");

    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, canvasWidth, canvasHeight)
    context.drawImage(outlineImage, 0, 0, canvasWidth, canvasHeight)

    let pixels;
    // Test for cross origin security error (SECURITY_ERR: DOM Exception 18)
    try {
        pixels = context.getImageData(0, 0, canvasWidth, canvasHeight)
    } catch (ex) {
        window.alert("Application cannot be run locally. Please run on a server.")
        return
    }

    // Make internal pixels array monochromatic for nicer filling without artifacts
    // at the edges caused by anti-aliasing
    for (var linearCoords = 0; linearCoords < pixels.data.length; linearCoords++) {
        if (linearCoords % 4 == 3) {
            continue
        }
        if (pixels.data[linearCoords] > 75) {
            pixels.data[linearCoords] = 255
        } else {
            pixels.data[linearCoords] = 0
        }
    }
    canvas.pixels = pixels
    canvas.outlineImage = outlineImage
}
