import "leaflet";
import "leaflet.markercluster";
import "leaflet.tilelayer.fallback";
import { GestureHandling } from "leaflet-gesture-handling";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
const gestureHandlingText = {
    touch: "Verschieben der Karte mit zwei Fingern",
    scroll: "Verwende Strg+Scrollen zum Zoomen der Karte",
    scrollMac: "Verwende \u2318+Scrollen zum Zoomen der Karte"
};

///////
// Wordmap-stuff
const churchMarkerConfig = {
    // size of the icon
    iconSize: [39, 53],
    // point of the icon which will correspond to marker's location
    iconAnchor: [19, 51]
};

const simpleChurchMarkerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="53.298" height="69.275" viewBox="0 0 53.298 69.275"><defs><style>.a{fill:#007f95;}.b{fill:#fff;}</style></defs><g transform="translate(-3.69)"><path class="a" d="M3.69,0,9.015,53.277h16l5.325,16,5.325-16h16L56.988,0Z"/><path class="b" d="M35.18,24.276H20.7V18.691L15.134,2,9.57,18.691V40.967c15.287-.026,7.067.057,27.71,0V29.84Z" transform="translate(6.849 2.33)"/></g></svg>`;

const complexChurchMarkerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="53.298" height="69.275" viewBox="0 0 53.298 69.275"><defs><style>.a{fill:#850057;}.b{fill:#fff;}</style></defs><g transform="translate(-3.69)"><path class="a" d="M3.69,0,9.015,53.277h16l5.325,16,5.325-16h16L56.988,0Z"/><path class="b" d="M35.18,24.276H20.7V18.691L15.134,2,9.57,18.691V40.967H12.9v-4.46c0-1.84,2.165-3.334,2.165-3.334s2.165,1.494,2.165,3.334h0v4.46H37.28V29.84ZM24.053,38.8H21.888V32.07a1.1,1.1,0,1,1,2.165,0h0Zm4.46,0H26.347V32.07a1.1,1.1,0,1,1,2.165,0h0Zm4.46,0H30.807V32.07a1.1,1.1,0,1,1,2.165,0h0Z" transform="translate(6.849 2.33)"/></g></svg>`;

const regionMarkerSvg = `<svg width="64" height="64" xmlns="http://www.w3.org/2000/svg"><g><title>background</title><rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/></g><g><title>Layer 1</title><path fill="#037f94" id="svg_2" d="m44.525,20.734c0,-6.919 -5.608,-12.527 -12.528,-12.527s-12.527,5.608 -12.527,12.527c0,9.913 12.527,24.581 12.527,24.581s12.528,-14.719 12.528,-24.581zm-18.706,-0.748c0,-3.413 2.767,-6.179 6.179,-6.179c3.412,0 6.179,2.766 6.179,6.179c0,3.413 -2.767,6.179 -6.179,6.179c-3.412,0 -6.179,-2.766 -6.179,-6.179z"/><circle fill="#037f94" id="svg_3" r="3.038" cy="19.986" cx="31.997"/><path fill="#037f94" id="svg_4" d="m39.08,40.958c-1.021,1.475 -1.979,2.761 -2.777,3.793c7.916,0.475 13.104,2.184 15.034,3.456c-2.261,1.491 -8.98,3.587 -19.338,3.587c-10.358,0 -17.077,-2.097 -19.338,-3.587c1.93,-1.271 7.114,-2.979 15.022,-3.455c-0.8,-1.032 -1.759,-2.317 -2.781,-3.792c-9.741,0.959 -16.816,3.841 -16.816,7.246c0,4.19 10.707,7.588 23.913,7.588c13.207,0 23.912,-3.397 23.912,-7.588c0.002,-3.407 -7.081,-6.29 -16.831,-7.248z"/></g></svg>`

const svgPrefix = "data:image/svg+xml;base64,";

/* This params can all be overriden via the params parameter (as done in `Map.elm`) or via a 
`config`-Object encoded as JSON-string (as used by the `map` content type).
This constant is also used as documentation so please make sure there is a default set for
every possible option.
*/
const defaultParams = {
    // The center of the map (if fitToWaypoints = false)
    center: [53.60833, 11.396347],
    // The default zoom level (if fitToWaypoints = false)
    zoom: 6,
    // Should scrolling and zooming be handled with gestures?
    // This should only be false for the main map
    gestureHandling: true,
    // Should the viewport be saved, so that it can be restored later?
    // This should only be true for the main map
    saveViewport: false,
    // A list of coords to be shown as path on the map. Coords in same format as center.
    waypoints: [],
    // Should the view be fit to the waypoints? Only works if waypoints is set.
    // Overrides zoom and center above.
    fitToWaypoints: false,
    // The color of the route drawn via waypoints
    waypointsColor: "#850057"
}

const churchMarkerIcons = {
    simple: L.icon({
        ...churchMarkerConfig,
        iconUrl: svgPrefix + btoa(simpleChurchMarkerSvg),
        type: "simple"
    }),
    complex: L.icon({
        ...churchMarkerConfig,
        iconUrl: svgPrefix + btoa(complexChurchMarkerSvg),
        type: "complex"
    })
};
const markerClusterIcon = cluster => {
    const complexMarker = cluster
        .getAllChildMarkers()
        .find(elem => elem.options.icon.options.type == "complex");
    const regionMarker = cluster
        .getAllChildMarkers()
        .find(elem => elem.options.icon.options.type == "region");
    const markerCls = complexMarker == undefined ? "simple" : "complex";
    const regionCls = regionMarker == undefined ? "" : "region"
    return L.divIcon({
        className: `map-marker-cluster ${markerCls} ${regionCls}`,
        html: cluster.getChildCount(),
        iconSize: [42, 42]
    });
};

const regionMarkerIcon = L.icon({
    iconSize: [64, 64],
    iconAnchor: [32, 48],
    iconUrl: svgPrefix + btoa(regionMarkerSvg),
    type: "region"
})

const attributionString =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

window.customElements.define(
    "leaflet-map",
    class LeafletMap extends HTMLElement {
        connectedCallback() {
            let config = {}
            if (this.config) {
                try {
                    config = JSON.parse(this.config)
                } catch (e) {
                    // This can only be a syntax error. Print it to the console, but don't fail.
                    console.error(e)
                }
            }

            const params = { ...defaultParams, ...config, ...this.params }

            const map = L.map(this, {
                zoomControl: false,
                attributionControl: false,
                gestureHandling: params.gestureHandling,
                gestureHandlingText: gestureHandlingText
            });
            map.setView(params.center, params.zoom);

            if (params.saveViewport) {
                map.on("moveend", () =>
                    document._app.ports.mapViewportChanged.send({ center: map.getCenter(), zoom: map.getZoom() })
                );
            }

            var tileSource = "https://nordkirche-osm.delink-server.net/map/{z}/{x}/{y}.png";
            if (L.Browser.retina) {
                tileSource = "https://nordkirche-osm.delink-server.net/map2x/{z}/{x}/{y}.png";
            }

            L.tileLayer
                .fallback(tileSource, {
                    attribution: attributionString,
                    maxZoom: 16,
                    minZoom: 5,
                    crossOrigin: "anonymous"
                })
                .addTo(map);

            L.control
                .zoom({
                    position: "topright"
                })
                .addTo(map);

            L.control
                .attribution({
                    prefix: '<a href="https://leafletjs.com">Leaflet</a>'
                })
                .addTo(map);

            const markerCluster = L.markerClusterGroup({
                iconCreateFunction: markerClusterIcon
            });

            this.churches.forEach(church => {
                const marker = L.marker([church.latitude, church.longitude], {
                    icon: church.complex ? churchMarkerIcons["complex"] : churchMarkerIcons["simple"]
                });

                marker.on("click", () => document._app.ports.mapChurchSelected.send(church.id));

                markerCluster.addLayer(marker);
            });
            this.regions.forEach(region => {
                const marker = L.marker([region.latitude, region.longitude], {
                    icon: regionMarkerIcon,
                });

                marker.on("click", () => document._app.ports.mapRegionSelected.send(region.id));

                markerCluster.addLayer(marker);
            });

            map.addLayer(markerCluster);

            if (params.waypoints) {
                const polyline = L.polyline(params.waypoints, { color: params.waypointsColor }).addTo(map);

                if (params.fitToWaypoints) {
                    map.fitBounds(polyline.getBounds());
                }
            }
        }
    }
);
