import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css"
import "./main.scss"

import { Elm } from "./Main.elm"

import * as matomo from './matomo'
import * as consent from './consent'
import * as altar from './altar'
import * as galleryGame from './galleryGame'
import * as towerGame from "./towerGame"

import './associationGame'
import './backToTop'
import './map'
import './groundplan'
import './leviteChair'
import './htmlViewer'
import './tangram'
import './searchingGame'
import './windowColor'
import "drag-drop-touch"

if (consent.hasTrackingConsent()) {
    matomo.activate()
}

var app = Elm.Main.init({
    node: document.getElementById("root"),
    flags: {
        showConsentBanner: consent.showConsentBanner(),
        startTime: Date.now()
    }
})

document._app = app

app.ports.renderAltar.subscribe(params => {
    requestAnimationFrame(() =>
        altar.renderAltar(params)
    )
})

app.ports.renderTripleAltar.subscribe(params => {
    requestAnimationFrame(() =>
        altar.renderTripleAltar(params)
    )
})

app.ports.controlAudioPlayer.subscribe(command => {
    requestAnimationFrame(() => {
        // Commands: load, pause, prev, next
        let player = document.getElementById("audio-player")
        let shouldPlay = false

        if (player) {
            // calculate shouldPlay before changing the player state
            switch (command) {
                case "next":
                    shouldPlay = !player.paused || player.ended
                    break
                case "prev":
                    shouldPlay = !player.paused
                    break
            }

            player.pause()

            switch (command) {
                case "load":
                case "next":
                case "prev":
                    requestAnimationFrame(() => {
                        player.load()
                        if (shouldPlay) {
                            player.play()
                        }
                    })
                    break
            }
        }
    })
})

app.ports.refreshView.subscribe(() => {
    window.scrollBy(1, 1)
    window.scrollBy(-1, -1)
})

app.ports.saveConsent.subscribe(params => {
    consent.saveConsent(params)
})

app.ports.reconfigureConsent.subscribe(() => {
    consent.reconfigureConsent()
})

app.ports.trackPageView.subscribe(() => {
    matomo.trackPageView()
})

app.ports.trackEvent.subscribe(params => {
    matomo.trackEvent(params)
})

app.ports.renderGalleryGame.subscribe(params => {
    galleryGame.render(params)
})

app.ports.renderTowerGame.subscribe((params) => {
    towerGame.render(params)
})

app.ports.openNavigation.subscribe(coords => {
    if /* if we're on iOS, open in Apple Maps */
        ((navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPad") != -1) ||
        (navigator.platform.indexOf("iPod") != -1))
        /* the important part seems to be maps:// - not the google part */
        window.open("maps://maps.google.com/maps?daddr=" + coords.latitude + "," + coords.longitude + "&ampll=")
    else /* else use Google */
        window.open("https://maps.google.com/maps?daddr=" + coords.latitude + "," + coords.longitude + "&ampll=")
})

app.ports.reload.subscribe(() => {
    // This event is triggered after the window.history.back() has finished processing
    addEventListener('popstate', () => {
        window.location.reload()
    })
    // This is async, so we need to use a callback
    window.history.back()
})

app.ports.back.subscribe(() => {
    history.back()
})

window.addEventListener('popstate', () => {
    app.ports.gotPopstatePosition.send(window.scrollY)
})


// sets vh-variable because the default vh doesn't count in collapsible browser bars
function setVh() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
}

window.addEventListener('resize', setVh)
window.addEventListener('orientationchange', setVh)

setVh()

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(new URL('serviceWorker.js', location.origin))
}

