const $ = require('jquery')
window.$ = window.jQuery = $

require('jquery-ui/ui/widget')
require('jquery-ui/ui/widgets/mouse')
require("jquery-ui/ui/data")
require("jquery-ui/ui/plugin")
require("jquery-ui/ui/safe-active-element")
require("jquery-ui/ui/scroll-parent")
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require('jquery-ui/ui/widgets/selectable')
require('jquery-ui-touch-punch');

window.customElements.define(
    "association-game",
    class AssociationGame extends HTMLElement {
        connectedCallback() {
            window.$ = $
            this.randomizeOptions();
            if ($(this).hasClass('association-game--textToImage')) {
                this.initGallery();
            }

            // Delay drag & drop init to avoid problems after reordering.
            window.setTimeout(() => this.initDraggability(), 50);
            window.popups = {};
        }

        initGallery() {
            const $targets = $(this).find('.association-game--targets').children()
            const switchGalleryImage = (offset) => {
                const currentIdx = $targets.toArray().findIndex((el) => !$(el).hasClass('d-none'))
                const targetIdx = (currentIdx + offset + $targets.length) % $targets.length
                $($targets[currentIdx]).addClass('d-none')
                $($targets[targetIdx]).removeClass('d-none')
            }

            $targets.first().removeClass('d-none')
            $(this).find('.image-gallery-arrow.right').on('click', () => {
                switchGalleryImage(1)
            })
            $(this).find('.image-gallery-arrow.left').on('click', () => {
                switchGalleryImage(-1)
            })
        }

        randomIndex(array) {
            return Math.floor(Math.random() * array.length);
        }

        randomizeOptions() {
            const options = $(this).find('.association-game--option');
            const optionsCopy = options.clone().removeClass('d-none');

            options.each((idx, option) => {
                $(option).replaceWith(optionsCopy.splice(this.randomIndex(optionsCopy), 1));
            });
        }

        initDraggability() {
            $(this).find('.association-game--option').each((idx, option) => {
                this.initDraggableOption(option);
            });
        }

        initDraggableOption(dragged) {
            const $dragged = $(dragged);
            const group = $dragged.data('group');
            $dragged.draggable({
                revert: 'invalid',
                containment: 'document'
            });

            $(this).find(`.association-game--target[data-group="${group}"]`).each((idx, target) => {
                const $target = $(target);
                $target.droppable({
                    accept: `.association-game--option[data-group="${group}"]`,
                    drop: () => {
                        $target.find('.association-game--answer').removeClass("d-none");
                        $target.data('success', true);
                        $dragged.remove();
                        this.showInfoText($target.data('infoText'));
                        this.checkComplete();
                    }
                });
            });
        }

        checkComplete() {
            const $game = $(this)
            let isComplete = true;
            $game.find('.association-game--target').each(function (idx, target) {
                isComplete = isComplete && $(target).data('success');
            });

            if (isComplete) {
                this.showSuccessText($game.data('successText'));
                $game.find('.association-game--target').addClass('solved');
            }
        }


        showInfoText(text) {
            this.showPopupText(text, 'info-text');
        }

        showSuccessText(text) {
            this.showPopupText(text, 'success-text');
        }

        showPopupText(text, classSuffix) {
            if (!text) return;

            let $popupText = $(`.association-game--${classSuffix}`);

            if (!$popupText.length) {
                $popupText = $(`<div class="association-game--${classSuffix}">${text}</div>`);
                $('body').append($popupText);
                $('body').click(() => $popupText.removeClass('visible'));
            } else {
                $popupText.text(text);

                if (window.popups[`${classSuffix}Popup`]) window.clearTimeout(window.popups[`${classSuffix}Popup`]);
            }

            window.setTimeout(() => {
                $popupText.addClass('visible');
            }, 0);

            window.popups[`${classSuffix}Popup`] = window.setTimeout(() => {
                $popupText.removeClass('visible');
            }, 15000);
        }
    }
);





