import "leaflet";
import "lodash";

const altars = [];

//config options

const overlayDivSelector = ".leaflet-overlay-pane";
const transitionDurationSec = 1.4;
const minZoom = -3;
const maxZoom = 4;

const centerBounds = [
  [0, 250],
  [1000, 750],
];

const innerLeftBounds = [
  [0, 0],
  [1000, 250],
];

const innerRightBounds = [
  [0, 750],
  [1000, 1000],
];

const outerLeftBounds = [
  [0, 250],
  [1000, 500],
];

const outerRightBounds = [
  [0, 500],
  [1000, 750],
];

const outerBounds = [
  [0, 0],
  [1000, 1000],
];

const emptyGif =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

// rendering

export const renderAltar = (params) => {
  const altarId = `folding-altar-${params.data.id}`;

  if (!document.getElementById(altarId)._leaflet_id) {
    initAltar(altarId, params.data, params.state.isOpen);
  } else {
    setAltarOpen(altarId, params.state.isOpen);
  }
};

export const renderTripleAltar = (params) => {
  const altarId = `triple-folding-altar-${params.data.id}`;

  if (!document.getElementById(altarId)._leaflet_id) {
    initTripleAltar(altarId, params.data, params.transition);
  } else {
    setTripleAltarState(altarId, params.transition.transition);
  }
};

let openedOnceInnerLeftImage;
let openedOnceInnerRightImage;
let openedOnceOuterLeftImage;
let openedOnceOuterRightImage;
let openedTwiceInnerLeftImage;
let openedTwiceInnerRightImage;
let openedTwiceOuterLeftImage;
let openedTwiceOuterRightImage;

const initAltar = (altarId, data, isOpen) => {
  const altar = L.map(altarId, {
    crs: L.CRS.Simple,
    minZoom: minZoom,
    maxZoom: maxZoom,
    zoomControl: false,
    zoomAnimation: false,
    attributionControl: false,
  });
  altars[altarId] = altar;

  L.control.zoom({ position: "topright" }).addTo(altar);

  const centerImageUrl = data.centerImageUrl || emptyGif;
  L.imageOverlay(centerImageUrl, centerBounds, {
    className: "center-image",
  }).addTo(altar);

  const innerLeftImageUrl = data.innerLeftImageUrl || emptyGif;
  L.imageOverlay(innerLeftImageUrl, innerLeftBounds, {
    className: "inner-left-image",
  }).addTo(altar);

  const innerRightImageUrl = data.innerRightImageUrl || emptyGif;
  L.imageOverlay(innerRightImageUrl, innerRightBounds, {
    className: "inner-right-image",
  }).addTo(altar);

  const outerLeftImageUrl = data.outerLeftImageUrl || emptyGif;
  L.imageOverlay(outerLeftImageUrl, outerLeftBounds, {
    className: "outer-left-image",
  }).addTo(altar);

  const outerRightImageUrl = data.outerRightImageUrl || emptyGif;
  L.imageOverlay(outerRightImageUrl, outerRightBounds, {
    className: "outer-right-image",
  }).addTo(altar);

  fixateZoom(altar);
  altar.setMaxBounds(outerBounds);
  altar.on("drag", () => {
    altar.panInsideBounds(outerBounds, { animate: false });
  });

  setTimeout(() => setAltarOpen(altarId, isOpen), 1);

  const altarDiv = document.querySelector(`#${altarId}`);
  const overlayDiv = altarDiv.querySelector(overlayDivSelector);

  altar.on("zoomstart", () => {
    //disable animation stuff
    overlayDiv.style.transition = "0s";
  });

  altar.on("zoomend", () => {
    // let "zoomend" finish before opening the altar. Otherwise the rotation gets overriden instantly.
    setTimeout(() => {
      const state = altarDiv.attributes["data-open"];
      setAltarOpen(altarId, state);
    }, 1);

    // re-enable animation only after a short while. Otherwise it could still trigger sometimes
    setTimeout(() => {
      overlayDiv.style.transition = `${transitionDurationSec}s`;
    }, 100);
  });

  overlayDiv.style.transition = `${transitionDurationSec}s`;
};

const initTripleAltar = (altarId, data) => {
  const altar = L.map(altarId, {
    crs: L.CRS.Simple,
    minZoom: minZoom,
    maxZoom: maxZoom,
    zoomControl: false,
    zoomAnimation: false,
    attributionControl: false,
  });
  altars[altarId] = altar;

  L.control.zoom({ position: "topright" }).addTo(altar);

  const centerImageUrl = data.centerImageUrl || emptyGif;
  L.imageOverlay(centerImageUrl, centerBounds, {
    className: "center-image",
  }).addTo(altar);

  const openedOnceInnerLeftImageUrl = data.openedOnceInnerLeftImageUrl || emptyGif;
  L.imageOverlay(openedOnceInnerLeftImageUrl, innerLeftBounds, {
    className: "opened-once-inner-left-image",
  }).addTo(altar);

  const openedOnceInnerRightImageUrl = data.openedOnceInnerRightImageUrl || emptyGif;
  L.imageOverlay(openedOnceInnerRightImageUrl, innerRightBounds, {
    className: "opened-once-inner-right-image",
  }).addTo(altar);

  const openedTwiceInnerLeftImageUrl = data.openedTwiceInnerLeftImageUrl || emptyGif;
  L.imageOverlay(openedTwiceInnerLeftImageUrl, innerLeftBounds, {
    className: "opened-twice-inner-left-image",
  }).addTo(altar);

  const openedTwiceInnerRightImageUrl =
    data.openedTwiceInnerRightImageUrl || emptyGif;
  L.imageOverlay(openedTwiceInnerRightImageUrl, innerRightBounds, {
    className: "opened-twice-inner-right-image",
  }).addTo(altar);

  const openedTwiceOuterLeftImageUrl = data.openedTwiceOuterLeftImageUrl || emptyGif;
  L.imageOverlay(openedTwiceOuterLeftImageUrl, outerLeftBounds, {
    className: "opened-twice-outer-left-image",
  }).addTo(altar);

  const openedTwiceOuterRightImageUrl =
    data.openedTwiceOuterRightImageUrl || emptyGif;
  L.imageOverlay(openedTwiceOuterRightImageUrl, outerRightBounds, {
    className: "opened-twice-outer-right-image",
  }).addTo(altar);

  const openedOnceOuterLeftImageUrl = data.openedOnceOuterLeftImageUrl || emptyGif;
  L.imageOverlay(openedOnceOuterLeftImageUrl, outerLeftBounds, {
    className: "opened-once-outer-left-image",
  }).addTo(altar);

  const openedOnceOuterRightImageUrl = data.openedOnceOuterRightImageUrl || emptyGif;
  L.imageOverlay(openedOnceOuterRightImageUrl, outerRightBounds, {
    className: "opened-once-outer-right-image",
  }).addTo(altar);

  fixateZoom(altar);
  altar.setMaxBounds(outerBounds);
  altar.on("drag", () => {
    altar.panInsideBounds(outerBounds, { animate: false });
  });

  const altarDiv = document.querySelector(`#${altarId}`);

  setTimeout(() => {
    openedOnceInnerLeftImage = altarDiv.querySelector(
      ".opened-once-inner-left-image"
    );
    openedOnceInnerRightImage = altarDiv.querySelector(
      ".opened-once-inner-right-image"
    );
    openedOnceOuterLeftImage = altarDiv.querySelector(
      ".opened-once-outer-left-image"
    );
    openedOnceOuterRightImage = altarDiv.querySelector(
      ".opened-once-outer-right-image"
    );
    openedTwiceInnerLeftImage = altarDiv.querySelector(
      ".opened-twice-inner-left-image"
    );
    openedTwiceInnerRightImage = altarDiv.querySelector(
      ".opened-twice-inner-right-image"
    );
    openedTwiceOuterLeftImage = altarDiv.querySelector(
      ".opened-twice-outer-left-image"
    );
    openedTwiceOuterRightImage = altarDiv.querySelector(
      ".opened-twice-outer-right-image"
    );
    replaceRotation(openedTwiceInnerLeftImage, 180);
    replaceRotation(openedTwiceInnerRightImage, -180);
    replaceRotation(openedTwiceOuterLeftImage, 0);
    replaceRotation(openedTwiceOuterRightImage, 0);
    replaceRotation(openedOnceInnerLeftImage, 180);
    replaceRotation(openedOnceInnerRightImage, -180);
    replaceRotation(openedOnceOuterLeftImage, 0);
    replaceRotation(openedOnceOuterRightImage, 0);
  }, 1);

  const overlayDiv = altarDiv.querySelector(overlayDivSelector);

  altar.on("zoomstart", () => {
    //disable animation stuff
    overlayDiv.style.transition = "0s";
  });

  altar.on("zoomend", () => {
    // let "zoomend" finish before opening the altar. Otherwise the rotation gets overriden instantly.
    setTimeout(() => {
      const transition = altarDiv.attributes["data-transition"];

      setTripleAltarState(altarId, transition);
    }, 1);

    // re-enable animation only after a short while. Otherwise it could still trigger sometimes
    setTimeout(() => {
      overlayDiv.style.transition = `${transitionDurationSec}s`;
    }, 100);
  });

  overlayDiv.style.transition = `${transitionDurationSec}s`;
};

const setAltarOpen = (altarId, isOpen) => {
  const altar = altars[altarId];
  const altarDiv = document.querySelector(`#${altarId}`);

  altarDiv.attributes["data-open"] = isOpen;

  const innerLeftImage = altarDiv.querySelector(".inner-left-image");
  const innerRightImage = altarDiv.querySelector(".inner-right-image");
  const outerLeftImage = altarDiv.querySelector(".outer-left-image");
  const outerRightImage = altarDiv.querySelector(".outer-right-image");

  if (isOpen) {
    replaceRotation(innerLeftImage, 0);
    replaceRotation(innerRightImage, 0);
    replaceRotation(outerLeftImage, -180);
    replaceRotation(outerRightImage, 180);
    unfixateZoom(altar);
  } else {
    replaceRotation(innerLeftImage, 180);
    replaceRotation(innerRightImage, -180);
    replaceRotation(outerLeftImage, 0);
    replaceRotation(outerRightImage, 0);
    //add a little extra delay, so animation is definitely done
    fixateZoom(altar, transitionDurationSec * 1000 + 100);
  }
};

const setTripleAltarState = (altarId, transition) => {
  const altar = altars[altarId];
  const altarDiv = document.querySelector(`#${altarId}`);

  altarDiv.attributes["data-transition"] = transition;

  if (transition === "ClosedToOpenedOnce") {
    replaceRotation(openedOnceInnerLeftImage, 0);
    replaceRotation(openedOnceInnerRightImage, 0);
    replaceRotation(openedOnceOuterLeftImage, -180);
    replaceRotation(openedOnceOuterRightImage, 180);
    setVisibility(openedTwiceInnerLeftImage, false);
    setVisibility(openedTwiceInnerRightImage, false);
    setVisibility(openedTwiceOuterLeftImage, false);
    setVisibility(openedTwiceOuterRightImage, false);
    unfixateZoom(altar);
  } else if (transition === "OpenedOnceToOpenedTwice") {
    setVisibility(openedOnceInnerLeftImage, false);
    setVisibility(openedOnceInnerRightImage, false);
    setVisibility(openedOnceOuterLeftImage, false);
    setVisibility(openedOnceOuterRightImage, false);
    replaceRotation(openedTwiceInnerLeftImage, 0);
    replaceRotation(openedTwiceInnerRightImage, 0);
    replaceRotation(openedTwiceOuterLeftImage, -180);
    replaceRotation(openedTwiceOuterRightImage, 180);
    unfixateZoom(altar);
  } else if (transition === "OpenedTwiceToOpenedOnce") {
    setVisibility(openedOnceInnerLeftImage, false);
    setVisibility(openedOnceInnerRightImage, false);
    setVisibility(openedOnceOuterLeftImage, false);
    setVisibility(openedOnceOuterRightImage, false);
    replaceRotation(openedTwiceInnerLeftImage, 180);
    replaceRotation(openedTwiceInnerRightImage, -180);
    replaceRotation(openedTwiceOuterLeftImage, 0);
    replaceRotation(openedTwiceOuterRightImage, 0);
    unfixateZoom(altar);
  } else if (transition === "OpenedOnceToClosed") {
    replaceRotation(openedOnceInnerLeftImage, 180);
    replaceRotation(openedOnceInnerRightImage, -180);
    replaceRotation(openedOnceOuterLeftImage, 0);
    replaceRotation(openedOnceOuterRightImage, 0);
    setVisibility(openedTwiceInnerLeftImage, false);
    setVisibility(openedTwiceInnerRightImage, false);
    setVisibility(openedTwiceOuterLeftImage, false);
    setVisibility(openedTwiceOuterRightImage, false);
    //add a little extra delay, so animation is definitely done
    fixateZoom(altar, transitionDurationSec * 1000 + 100);
  }
};

const replaceRotation = (
  div,
  rotation,
  visibility = Math.abs(rotation) <= 90
) => {
  div.style.transform = `${div.style.transform.replace(
    /rotateY\([^)]*\)/,
    ""
  )} rotateY(${rotation}deg)`;

  setVisibility(div, visibility ? "visible" : "hidden");
};

const setVisibility = (div, visibility) => {
  div.style.visibility = visibility;
};

const unfixateZoom = (altar) => {
  altar.setMaxZoom(maxZoom);
  altar.setMinZoom(minZoom);
};

const fixateZoom = (altar, delay = 0) => {
  setTimeout(() => {
    altar.fitBounds(centerBounds);
    const fixedZoom = altar.getZoom();
    altar.setMinZoom(fixedZoom);
    altar.setMaxZoom(fixedZoom);
  }, delay);
};
