import * as matomo from './matomo';

const saveCookie = (key, value) => {
    const cookieExpires = new Date();
    cookieExpires.setFullYear(cookieExpires.getFullYear() + 1);
    document.cookie = `${key}=${value}; expires=${cookieExpires.toUTCString()}; path=/`;
}

const saveCookies = params => {
    for (var key in params) {
        saveCookie(key, params[key]);
    }
}

export const saveConsent = params => {
    if (params.functionalConsent) {
        saveCookies(params);
    } else {
        deleteConsentCookies();
        localStorage.clear();
    }

    if (params.trackingConsent) {
        matomo.activate()
    } else {
        deleteMatomoCookies();
    }
}

const deleteCookie = (cookie) => {
    document.cookie = cookie.replace(/^ +/, "").replace(/(=.*|$)/, `=;expires=${new Date().toUTCString()}; path=/`);
}

const deleteConsentCookies = () => {
    deleteCookie("functionalConsent");
    deleteCookie("trackingConsent");
}

const deleteMatomoCookies = () => {
    document.cookie.split(";").filter(matomo.isMatomoCookie).forEach(deleteCookie);
}

export const reconfigureConsent = () => {
    deleteConsentCookies();
    location.reload();
}

const readCookie = name => {
    //regexp from https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
    const regex = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`);
    const cookieValue = document.cookie.replace(regex, "$1");
    return cookieValue;
}

export const hasTrackingConsent = () => {
    return readCookie("trackingConsent") == "true";
}

export const hasFunctionalConsent = () => {
    return readCookie("functionalConsent") == "true";
}

export const showConsentBanner = () => {
    return !hasFunctionalConsent();
}

// This functions are for iOS to hide the consentBanner if ATTrackingManager is used
window.disableAllTracking = () => {
    document._app.ports.consentNone.send()
}

window.acceptAllTracking = () => {
    document._app.ports.consentAll.send()
}
