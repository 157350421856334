window.customElements.define(
    "back-to-top",
    class BackToTop extends HTMLElement {
        startFadeAt = 100
        finishFadeAfter = 400

        connectedCallback() {
            this.addEventListener('click', this.scrollToTop)

            this.setOpacity()
            window.addEventListener('scroll', this.setOpacity)
        }

        disconnectedCallback() {
            window.removeEventListener('scroll', this.setOpacity)
        }

        scrollToTop = () => {
            window.scroll({ top: 0, behavior: 'smooth' })
        }

        setOpacity = () => {
            const rawOpacity = (window.scrollY - this.startFadeAt) / this.finishFadeAfter
            const opacity = Math.max(0, Math.min(rawOpacity, 1))
            this.style.opacity = opacity
        }
    })
