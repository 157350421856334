window._paq = window._paq || [];
delete window._trlk;

export const activate = () => {
  (function () {
    var u = "//www.nordkirche.de/piwik/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '220']);
    var d = document, g = d.createElement('script'), h = d.getElementsByTagName('head')[0];
    g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'matomo.js'; h.appendChild(g);
  })();
  _paq.push(['enableLinkTracking']);
  trackPageView();
}


export const trackPageView = () => {
  if (!window._trlk) {
    //set tracking lock to block multiple tracking requests
    //for example browser-back triggers tracking twice
    window._trlk = true;
    //wait a little for page title to have a chance to update
    setTimeout(() => {
      //unset tracking lock
      delete window._trlk
      doTrackPageView();
    }, 500);
  }
}

const doTrackPageView = () => {
  //matomo doesn't update the title and url, if trackPageView is tracked multiple times without a refresh
  _paq.push(['setDocumentTitle', document.title]);
  _paq.push(['setCustomUrl', window.location.href]);
  _paq.push(['trackPageView']);
}

export const trackEvent = params => {
  //delay as in trackPageView to have events send synchronously
  setTimeout(() => {
    doTrackEvent(params);
  }, 500);
}

const doTrackEvent = params => {
  params.unshift('trackEvent');
  _paq.push(params);
}

export const isMatomoCookie = cookie => {
  return cookie.replace(/^ +/, "").startsWith("_pk_");
}
