window.customElements.define(
    "html-viewer",
    class WindowColor extends HTMLElement {
        connectedCallback() {
            this.innerHTML = this.content
            this.executeScriptElements()
        }

        // Source: https://stackoverflow.com/a/69190644
        executeScriptElements() {
            const scriptElements = this.querySelectorAll("script");

            Array.from(scriptElements).forEach((scriptElement) => {
                const clonedElement = document.createElement("script");

                Array.from(scriptElement.attributes).forEach((attribute) => {
                    clonedElement.setAttribute(attribute.name, attribute.value);
                });

                clonedElement.text = scriptElement.text;

                scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
            });
        }

    });

